// ../../../react-shim.js
import React from "react";

// src/tooltip.tsx
import { popperCSSVars as popperCSSVars2 } from "@chakra-ui/popper";
import { Portal } from "@chakra-ui/portal";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useStyleConfig,
  useTheme,
  getCSSVar
} from "@chakra-ui/system";

// ../../utilities/object-utils/src/index.ts
function omit(object, keysToOmit = []) {
  const clone = Object.assign({}, object);
  for (const key of keysToOmit) {
    if (key in clone)
      delete clone[key];
  }
  return clone;
}
function pick(object, keysToPick) {
  const result = {};
  for (const key of keysToPick) {
    if (key in object) {
      result[key] = object[key];
    }
  }
  return result;
}

// src/tooltip.tsx
import { AnimatePresence, motion } from "framer-motion";
import { Children, cloneElement } from "react";

// src/tooltip.transition.tsx
var scale = {
  exit: {
    scale: 0.85,
    opacity: 0,
    transition: {
      opacity: { duration: 0.15, easings: "easeInOut" },
      scale: { duration: 0.2, easings: "easeInOut" }
    }
  },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      opacity: { easings: "easeOut", duration: 0.2 },
      scale: { duration: 0.2, ease: [0.175, 0.885, 0.4, 1.1] }
    }
  }
};

// src/use-tooltip.ts
import { useEventListener } from "@chakra-ui/react-use-event-listener";
import { useDisclosure } from "@chakra-ui/react-use-disclosure";
import { popperCSSVars, usePopper } from "@chakra-ui/popper";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";

// ../../utilities/shared-utils/src/index.ts
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/use-tooltip.ts
import {
  useCallback,
  useEffect,
  useRef,
  useId
} from "react";
var getDoc = (ref) => {
  var _a;
  return ((_a = ref.current) == null ? void 0 : _a.ownerDocument) || document;
};
var getWin = (ref) => {
  var _a, _b;
  return ((_b = (_a = ref.current) == null ? void 0 : _a.ownerDocument) == null ? void 0 : _b.defaultView) || window;
};
function useTooltip(props = {}) {
  const {
    openDelay = 0,
    closeDelay = 0,
    closeOnClick = true,
    closeOnMouseDown,
    closeOnScroll,
    closeOnPointerDown = closeOnMouseDown,
    closeOnEsc = true,
    onOpen: onOpenProp,
    onClose: onCloseProp,
    placement,
    id,
    isOpen: isOpenProp,
    defaultIsOpen,
    arrowSize = 10,
    arrowShadowColor,
    arrowPadding,
    modifiers,
    isDisabled,
    gutter,
    offset,
    direction,
    ...htmlProps
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: isOpenProp,
    defaultIsOpen,
    onOpen: onOpenProp,
    onClose: onCloseProp
  });
  const { referenceRef, getPopperProps, getArrowInnerProps, getArrowProps } = usePopper({
    enabled: isOpen,
    placement,
    arrowPadding,
    modifiers,
    gutter,
    offset,
    direction
  });
  const uuid = useId();
  const uid = id ?? uuid;
  const tooltipId = `tooltip-${uid}`;
  const ref = useRef(null);
  const enterTimeout = useRef();
  const exitTimeout = useRef();
  const closeNow = useCallback(() => {
    if (exitTimeout.current) {
      clearTimeout(exitTimeout.current);
      exitTimeout.current = void 0;
    }
    onClose();
  }, [onClose]);
  const dispatchCloseEvent = useCloseEvent(ref, closeNow);
  const openWithDelay = useCallback(() => {
    if (!isDisabled && !enterTimeout.current) {
      dispatchCloseEvent();
      const win = getWin(ref);
      enterTimeout.current = win.setTimeout(onOpen, openDelay);
    }
  }, [dispatchCloseEvent, isDisabled, onOpen, openDelay]);
  const closeWithDelay = useCallback(() => {
    if (enterTimeout.current) {
      clearTimeout(enterTimeout.current);
      enterTimeout.current = void 0;
    }
    const win = getWin(ref);
    exitTimeout.current = win.setTimeout(closeNow, closeDelay);
  }, [closeDelay, closeNow]);
  const onClick = useCallback(() => {
    if (isOpen && closeOnClick) {
      closeWithDelay();
    }
  }, [closeOnClick, closeWithDelay, isOpen]);
  const onPointerDown = useCallback(() => {
    if (isOpen && closeOnPointerDown) {
      closeWithDelay();
    }
  }, [closeOnPointerDown, closeWithDelay, isOpen]);
  const onKeyDown = useCallback((event) => {
    if (isOpen && event.key === "Escape") {
      closeWithDelay();
    }
  }, [isOpen, closeWithDelay]);
  useEventListener(() => getDoc(ref), "keydown", closeOnEsc ? onKeyDown : void 0);
  useEventListener(() => getDoc(ref), "scroll", () => {
    if (isOpen && closeOnScroll) {
      closeNow();
    }
  });
  useEffect(() => () => {
    clearTimeout(enterTimeout.current);
    clearTimeout(exitTimeout.current);
  }, []);
  useEventListener(() => ref.current, "pointerleave", closeWithDelay);
  const getTriggerProps = useCallback((props2 = {}, _ref = null) => {
    const triggerProps = {
      ...props2,
      ref: mergeRefs(ref, _ref, referenceRef),
      onPointerEnter: callAllHandlers(props2.onPointerEnter, (e) => {
        if (e.pointerType === "touch")
          return;
        openWithDelay();
      }),
      onClick: callAllHandlers(props2.onClick, onClick),
      onPointerDown: callAllHandlers(props2.onPointerDown, onPointerDown),
      onFocus: callAllHandlers(props2.onFocus, openWithDelay),
      onBlur: callAllHandlers(props2.onBlur, closeWithDelay),
      "aria-describedby": isOpen ? tooltipId : void 0
    };
    return triggerProps;
  }, [
    openWithDelay,
    closeWithDelay,
    onPointerDown,
    isOpen,
    tooltipId,
    onClick,
    referenceRef
  ]);
  const getTooltipPositionerProps = useCallback((props2 = {}, forwardedRef = null) => getPopperProps({
    ...props2,
    style: {
      ...props2.style,
      [popperCSSVars.arrowSize.var]: arrowSize ? `${arrowSize}px` : void 0,
      [popperCSSVars.arrowShadowColor.var]: arrowShadowColor
    }
  }, forwardedRef), [getPopperProps, arrowSize, arrowShadowColor]);
  const getTooltipProps = useCallback((props2 = {}, ref2 = null) => {
    const styles = {
      ...props2.style,
      position: "relative",
      transformOrigin: popperCSSVars.transformOrigin.varRef
    };
    return {
      ref: ref2,
      ...htmlProps,
      ...props2,
      id: tooltipId,
      role: "tooltip",
      style: styles
    };
  }, [htmlProps, tooltipId]);
  return {
    isOpen,
    show: openWithDelay,
    hide: closeWithDelay,
    getTriggerProps,
    getTooltipProps,
    getTooltipPositionerProps,
    getArrowProps,
    getArrowInnerProps
  };
}
var closeEventName = "chakra-ui:close-tooltip";
function useCloseEvent(ref, close) {
  useEffect(() => {
    const doc = getDoc(ref);
    doc.addEventListener(closeEventName, close);
    return () => doc.removeEventListener(closeEventName, close);
  }, [close, ref]);
  return () => {
    const doc = getDoc(ref);
    const win = getWin(ref);
    doc.dispatchEvent(new win.CustomEvent(closeEventName));
  };
}

// src/tooltip.tsx
var MotionDiv = chakra(motion.div);
var Tooltip = forwardRef((props, ref) => {
  const styles = useStyleConfig("Tooltip", props);
  const ownProps = omitThemingProps(props);
  const theme = useTheme();
  const {
    children,
    label,
    shouldWrapChildren,
    "aria-label": ariaLabel,
    hasArrow,
    bg,
    portalProps,
    background,
    backgroundColor,
    bgColor,
    motionProps,
    ...rest
  } = ownProps;
  const userDefinedBg = background ?? backgroundColor ?? bg ?? bgColor;
  if (userDefinedBg) {
    styles.bg = userDefinedBg;
    const bgVar = getCSSVar(theme, "colors", userDefinedBg);
    styles[popperCSSVars2.arrowBg.var] = bgVar;
  }
  const tooltip = useTooltip({ ...rest, direction: theme.direction });
  const shouldWrap = typeof children === "string" || shouldWrapChildren;
  let trigger;
  if (shouldWrap) {
    trigger = /* @__PURE__ */ React.createElement(chakra.span, {
      display: "inline-block",
      tabIndex: 0,
      ...tooltip.getTriggerProps()
    }, children);
  } else {
    const child = Children.only(children);
    trigger = cloneElement(child, tooltip.getTriggerProps(child.props, child.ref));
  }
  const hasAriaLabel = !!ariaLabel;
  const _tooltipProps = tooltip.getTooltipProps({}, ref);
  const tooltipProps = hasAriaLabel ? omit(_tooltipProps, ["role", "id"]) : _tooltipProps;
  const srOnlyProps = pick(_tooltipProps, ["role", "id"]);
  if (!label) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, children);
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, trigger, /* @__PURE__ */ React.createElement(AnimatePresence, null, tooltip.isOpen && /* @__PURE__ */ React.createElement(Portal, {
    ...portalProps
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    ...tooltip.getTooltipPositionerProps(),
    __css: {
      zIndex: styles.zIndex,
      pointerEvents: "none"
    }
  }, /* @__PURE__ */ React.createElement(MotionDiv, {
    variants: scale,
    initial: "exit",
    animate: "enter",
    exit: "exit",
    ...motionProps,
    ...tooltipProps,
    __css: styles
  }, label, hasAriaLabel && /* @__PURE__ */ React.createElement(chakra.span, {
    srOnly: true,
    ...srOnlyProps
  }, ariaLabel), hasArrow && /* @__PURE__ */ React.createElement(chakra.div, {
    "data-popper-arrow": true,
    className: "chakra-tooltip__arrow-wrapper"
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    "data-popper-arrow-inner": true,
    className: "chakra-tooltip__arrow",
    __css: { bg: styles.bg }
  })))))));
});
Tooltip.displayName = "Tooltip";
export {
  Tooltip,
  useTooltip
};
